@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

:root {
    --green: #00b76b;
    --blue: #029df0;
    --orange: #f09502;
    --pink: #E83875;
    --gradient-blue: #8cdbff;
    --gradient-pink: #febbcf;
}

/* General styles */
html,
body,
#root,
.App {
    height: 100%;
    width: 100%;
}

p,
h3,
h2,
h1,
div {
    font-family: 'Poppins', sans-serif;
}

.App {
    display: flex;
    position: relative;
}

/* Page section */
.page {
    height: 100%;
    width: 100%;
}

.page-inner {
    border-radius: 30px 30px 0 0;
    background: linear-gradient(-45deg, #8cdbff71, #febbcfa3);
    background-size: 200% 200%;
    animation: gradient 8s ease infinite;
    height: 100%;
    width: 100%;
    padding: 30px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* box-shadow: inset 0 10px 10px 0 rgb(0 0 0 / 0.1); */
    z-index: 999;
}

.page-inner::-webkit-scrollbar {
    display: none;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* Sidebar */
.sidebar-footer {
    position: absolute;
    bottom: 0;
}

.ps-sidebar-container {
    border-radius: 30px;
    padding-right: 20px;
    padding-top: 30px;
    padding-left: 20px;
    width: 100%;
}

.ps-menu-button {
    border-radius: 15px;
    background-color: #00000000;
    background: linear-gradient(to right, transparent, transparent);
    margin-bottom: 10px;
    background-size: 200% 200%;
    transition: all 0.2s ease;
}

.gradient-button {
    background-color: #fd99e7;
    background: linear-gradient(-45deg, var(--gradient-blue), var(--gradient-pink));
    margin-bottom: 10px;
    background-size: 200% 200%;
    transition: all 0.2s ease;
}

.ps-menu-button:hover,
.active .ps-menu-button {
    background-color: transparent;
    background: linear-gradient(-45deg, var(--gradient-blue), var(--gradient-pink));
    background-size: 200% 200%;
    animation: gradient 8s ease infinite;
    color: #000;
}

.ps-menu-button:hover .ps-menu-label,
.ps-menu-button:hover .ps-menu-icon>svg {
    color: #000;
}

.active .ps-menu-button .ps-menu-label,
.active .ps-menu-button .ps-menu-icon>svg {
    color: #000;
}

.ps-menu-button .ps-menu-label {
    color: #fff;
    transition: all 0.2s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

/* Card Item */
.info-card {
    width: 22%;
    min-width: 250px;
}

/* Green */
.icon-bg-green,
.graph-bg-green {
    background: linear-gradient(180deg, var(--green), #019356);
    box-shadow: 0px 10px 30px -10px var(--green);
}

.value-color-green,
.text-color-green {
    color: var(--green);
}

/* Pink */
.icon-bg-pink,
.graph-bg-pink {
    background: linear-gradient(180deg, var(--pink), #c93367);
    box-shadow: 0px 10px 30px -10px var(--pink);
}

.value-color-pink,
.text-color-pink {
    color: var(--pink);
}

/* Orange */
.icon-bg-orange,
.graph-bg-orange {
    background: linear-gradient(180deg, var(--orange), #d68401);
    box-shadow: 0px 10px 30px -10px var(--orange);
}

.value-color-orange,
.text-color-orange {
    color: var(--orange);
}

/* Blue */
.icon-bg-blue,
.graph-bg-blue {
    background: linear-gradient(180deg, var(--blue), #0487ce);
    box-shadow: 0px 10px 30px -10px var(--blue);
}

.value-color-blue,
.text-color-blue {
    color: var(--blue);
}


/* graph card */
.graph-card {
    width: 30%;
    min-width: 280px;
}

/* Checkbox */

.member-modal-checkboxes .ant-checkbox .ant-checkbox-inner {
    transform: scale(1.3);
    margin-right: 5px;
}

.primary-color {
    color: var(--pink);
}

.checkbox-orange .ant-checkbox-checked .ant-checkbox-inner,
.ant-modal .checkbox-orange .ant-checkbox:hover .ant-checkbox-inner,
.ant-modal .checkbox-orange.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--orange) !important;
    border-color: var(--orange) !important;
}

.checkbox-orange .ant-checkbox .ant-checkbox-inner,
.checkbox-orange .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--orange);
}

.checkbox-green .ant-checkbox-checked .ant-checkbox-inner,
.ant-modal .checkbox-green .ant-checkbox:hover .ant-checkbox-inner,
.ant-modal .checkbox-green.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}

.checkbox-green .ant-checkbox .ant-checkbox-inner,
.checkbox-green .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--green);
}

.checkbox-pink .ant-checkbox-checked .ant-checkbox-inner,
.ant-modal .checkbox-pink .ant-checkbox:hover .ant-checkbox-inner,
.ant-modal .checkbox-pink.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--pink) !important;
    border-color: var(--pink) !important;
}

.checkbox-pink .ant-checkbox .ant-checkbox-inner {
    border-color: var(--pink);
}

.checkbox-blue .ant-checkbox-checked .ant-checkbox-inner,
.ant-modal .checkbox-blue .ant-checkbox:hover .ant-checkbox-inner,
.ant-modal .checkbox-blue.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
}

.checkbox-blue .ant-checkbox .ant-checkbox-inner {
    border-color: var(--blue);
}

/* Table */
.ant-table-thead tr th:first-child {
    border-start-start-radius: 30px !important;
}

.ant-table-thead tr th:last-child {
    border-start-end-radius: 30px !important;
}

.ant-table-wrapper .ant-table {
    border-radius: 30px !important;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.ant-table-wrapper .ant-table-footer {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

/* Modal */
.ant-modal .ant-card-body {
    display: flex;
    justify-content: space-evenly;
}

.ant-modal .ant-modal-content {
    border-radius: 20px;
}

.ant-modal .ant-checkbox-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.ant-popconfirm .ant-popconfirm-buttons button.ant-btn-primary {
    background-color: rgb(220 38 38);
}

/* Buttons */
.ant-table-filter-dropdown .ant-btn.ant-btn-primary {
    background-color: #1677ff;
}

.ok-button {
    border-color: rgb(34 197 94) !important;
    background-color: rgb(34 197 94) !important;
    color: white;
}

.ok-button:hover {
    background-color: transparent !important;
    border-color: rgb(34 197 94) !important;
    color: rgb(34 197 94) !important;
}

.cancel-button {
    border-color: rgb(220 38 38) !important;
    background-color: transparent !important;
    color: rgb(220 38 38) !important;
}

.cancel-button:hover {
    background-color: rgb(220 38 38) !important;
    border-color: rgb(220 38 38) !important;
    color: #ffffff !important;
}

/* Message Box */
.ant-message-notice .ant-message-notice-content {
    background: #000000 !important;
    color: #fff;
}

/* Subgroup Card */
.subgroup-card {
    min-width: 270px;
}